@font-face {
  font-family: "retro";
  src: url("./assets/fonts/retro_computer_personal_use.ttf");
}
@font-face {
  font-family: "mabryRegular";
  src: url("../src/assets/fonts/Mabry/mabry-regular-pro.ttf");
}
@font-face {
  font-family: "mabryMedium";
  src: url("../src/assets/fonts/Mabry/Mabry-Pro-Medium.ttf");
}
@font-face {
  font-family: "mabryLight";
  src: url("../src/assets/fonts/Mabry/MabryPro-Light.ttf");
}
@font-face {
  font-family: "mabryBlack";
  src: url("../src/assets/fonts/Mabry/Mabry-Pro-Black.ttf");
}
@font-face {
  font-family: "mabryBold";
  src: url("../src/assets/fonts/Mabry/MabryPro-Bold.ttf");
}

body,
html {
  margin: 0;
  font-family: "mabryRegular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.AnimationFinished {
  z-index: 50;
  color: white;
  width: fit-content;
  padding: 12px 20px;
  transform: translateY(0);
  border-radius: 2px;
  text-transform: uppercase;
  animation-name: bounce;
  animation-duration: 0.2s;
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  80% {
    transform: translateY(5px);
  }
}
.shadowTheme {
  box-shadow: 3px 2px 0 0 #290640;
}
.animOpacity {
  animation-name: opa;
  animation-duration: 0.5s;
}
@keyframes opa {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.transitionWidth {
  animation: wTrans 0.5s;
  width: 0;
}
.transitionClose {
  animation: wTrans2 0.5s;
}

@keyframes wTrans {
  0% {
    width: 0;
  }
  100% {
    width: 60%;
  }
}
@keyframes wTrans2 {
  0% {
    width: 80%;
  }
  100% {
    width: 0;
  }
}

.tabAnim {
  width: 100%;
  animation-name: tab;
  animation-duration: 0.5s;
}
@keyframes tab {
  0% {
    width: 0;
  }
  98% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.marquee {
  animation: marquee 10s linear infinite;
}
.marquee2 {
  animation: marquee2 10s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-80%);
  }
}
@keyframes marquee2 {
  from {
    transform: translateX(-80%);
  }
  to {
    transform: translateX(0%);
  }
}
img.\31 {
  width: 110px;
}
img.\32 {
  width: 120px;
  filter: brightness(9);
}

@media screen and (max-width: 768px) {
  img.\31 {
    width: 80px;
  }
  img.\32 {
    width: 80px;
    filter: brightness(9);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
